import React from 'react'
import { Box } from '@mui/material'

export default function SquareLogo() {
	return (
		<>
			<Box sx={{ textAlign: 'center' }}>
				<Box
					component='img'
					src='https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FSquarelogo%2FsquareLogo.png?alt=media&token=dec5a971-5eeb-4f66-b43f-63400e0310fd'
					alt='未来整体'
					width='100%'
				/>
			</Box>
		</>
	)
}
