import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Container } from '@mui/material'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<Container maxWidth={'sm'} sx={{ p: 0 }}>
			<App />
		</Container>
	</React.StrictMode>
)

reportWebVitals()
