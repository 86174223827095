import React from 'react'
import { Box } from '@mui/material'

export default function TopImage() {
	return (
		<>
			<Box sx={{ textAlign: 'center' }}>
				<Box
					component='img'
					src='https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FTopImage%2FTopImageV2.jpeg?alt=media&token=e33ded44-67d8-48ed-9a66-5431361a5705'
					alt='繰り返す痛み・痺れが当院の整体＆姿勢矯正で早期改善へ'
					width='100%'
				/>
			</Box>
		</>
	)
}
