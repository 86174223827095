import React from 'react'
import { Typography, Box } from '@mui/material'
interface SubtitleProps {
	text: string
}

export default function CustomSubtitle({ text }: SubtitleProps) {
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					top: '0',
					padding: '10px',
					backgroundColor: '#F9F1E8',
				}}
			>
				<Typography
					sx={{
						fontSize: '36px',
						color: '#000',
						paddingLeft: '10px',
						fontWeight: 'bold',
					}}
				>
					{text}
				</Typography>
			</Box>
		</>
	)
}
