import React from 'react'
import CustomSubtitle from './CustomSubtitle'
import { Box, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme } from '@mui/material/styles'
import SquareLogo from './SquareLogo'
import ButtonGroup from './ButtonGroup'

export default function Flow() {
	const theme = createTheme({
		typography: {
			fontFamily: '"BIZ UDPMincho", serif',
		},
	})
	const buttonPatternFlow: {
		title: string
		link: string
		scroll: boolean
	}[] = [
		{
			title: 'トップ',
			link: '/',
			scroll: false,
		},
		{ title: '当院の特徴', link: 'features', scroll: false },
		{ title: 'スタッフ紹介', link: 'introduction', scroll: false },
		{ title: '予約・問い合わせ', link: 'infomation', scroll: false },
		{ title: 'お客様の声', link: 'customerReviews', scroll: false },
	]

	const contents: { title: string; text: string[]; src: string }[] = [
		{
			title: '問診',
			text: [
				'まずは問診票をご記入頂き、15分以上かけてお悩みについて、お話を聞かせていただきます。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FFlow%2FFlow1.jpeg?alt=media&token=fb17665e-a93f-4e49-86d5-e9cc55df5f5c',
		},
		{
			title: '検査',
			text: [
				'姿勢や関節の動きに異常がないか検査します。',
				'当院では、体組成計を使い筋肉量なども、細かく検査いたします。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FFlow%2FFlow2.jpeg?alt=media&token=700eb092-1b46-4ed4-a813-cd2211828863',
		},
		{
			title: 'カウンセリング',
			text: [
				'検査結果をもとにお悩みの原因を、出来る限り専門用語を一切使わずにご説明します。そして今後の改善計画なども、一緒に説明させていただいてから、施術に入ります。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FFlow%2FFlow3.jpeg?alt=media&token=6b5c333a-b7f4-4376-9c95-904f7ad07a1f',
		},
		{
			title: '施術',
			text: [
				'早期改善に進めるよう最善を尽くします。ボキボキ・強い指圧は行いません。優しい整体ですのでご安心ください。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FFlow%2FFlow4.jpeg?alt=media&token=6fb927f9-e241-43fa-923f-8403817577a2',
		},
	]
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box sx={{ backgroundColor: '#F9F1E8' }}>
				<Box sx={{ p: '20px' }}>
					<SquareLogo />
				</Box>
				<CustomSubtitle text='施術までの流れ' />
				{contents.map((content) => (
					<Box>
						<Box
							sx={{
								backgroundColor: '#F1C594',
								textAlign: 'center',
								paddingX: '15px',
								paddingY: '5px',
								my: 2,
							}}
						>
							<Typography
								sx={{
									color: '#000',
									fontSize: '26px',
									fontWeight: 'bold',
									fontFamily: '"BIZ UDPGothic", serif',
								}}
							>
								{content.title}
							</Typography>
						</Box>
						<Box
							component='img'
							src={content.src}
							alt={content.title}
							width='100%'
						/>
						<Box>
							{content.text.map((text) => (
								<Box key={text}>
									<Typography sx={{ color: '#000', fontSize: '20px' }}>
										{text}
									</Typography>
								</Box>
							))}
						</Box>
					</Box>
				))}
				<ButtonGroup pattern={buttonPatternFlow} />
			</Box>
		</ThemeProvider>
	)
}
