import React from 'react'
import CustomSubtitle from './CustomSubtitle'
import { Box, Typography, Container } from '@mui/material'
import Grid from '@mui/material/Grid'

const recommendations: {
	url: string
	person: string
	comment: string[]
	profession: string
	id: number
}[] = [
	{
		url: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FRecommendation%2F1.png?alt=media&token=f65692fe-6a23-4fe1-9c77-ef72b157a2c8',
		person: '山本拓海先生',
		comment: [
			'技術・実績はトップクラスですので,重い症状でも安心してお任せできます',
		],
		profession: '楽天イーグルス専属トレーナー',
		id: 1,
	},
	{
		url: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FRecommendation%2F2.png?alt=media&token=32c7e198-3c9a-4fb6-a632-04aaa98614e7',
		person: '大竹圭介先生',
		comment: ['健康なお身体を手に入れたい方は未来整体さんで間違いありません。'],
		profession: '柔道整復師/鍼灸師',
		id: 2,
	},
	{
		url: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FRecommendation%2F3.png?alt=media&token=c342c5bf-7fd2-460e-8d7d-ddd94dcb08ee',
		person: '谷口愛さん',
		comment: [
			'骨盤矯正・姿勢矯正もトップクラスなので再発しにくいカラダに導いてくれます。',
		],
		profession: '看護師',
		id: 3,
	},
	{
		url: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FRecommendation%2F4.png?alt=media&token=7d8152b0-34d9-4599-aa26-4085a229cb35',
		person: '礒山航さん',
		comment: ['症状改善はもちろん、人柄も尊敬できる先生です。'],
		profession: '消防士',
		id: 4,
	},
]

export default function Recommendation() {
	return (
		<Box sx={{ backgroundColor: '#F9DBB8', pb: 2 }}>
			<CustomSubtitle text='専門家からの推薦' />
			{recommendations.map((content) => (
				<Grid
					container
					spacing={1}
					key={content.id}
					alignItems='center'
					justifyContent='center'
					sx={{ mt: 1 }}
				>
					<Grid item xs={3}>
						<Box sx={{ textAlign: 'center' }}>
							<Box
								component='img'
								src={content.url}
								alt={content.person}
								width='80%'
							/>
							<Typography
								align='center'
								sx={{ fontSize: '9px', color: '#000' }}
							>
								{content.person}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={9}>
						<Container>
							<Typography
								align='center'
								sx={{
									fontSize: '16px',
									color: '#000',
									mb: 1,
									fontWeight: 'bold',
								}}
							>
								{content.profession}
							</Typography>
							{content.comment.map((comment) => (
								<Typography
									align='center'
									key={comment}
									sx={{ fontSize: '16px', color: '#000' }}
								>
									{comment}
								</Typography>
							))}
						</Container>
					</Grid>
				</Grid>
			))}
		</Box>
	)
}
