import React from 'react'
import CustomSubtitle from './CustomSubtitle'
import { Box, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme } from '@mui/material/styles'
import SquareLogo from './SquareLogo'
import ButtonGroup from './ButtonGroup'

export default function Features() {
	const theme = createTheme({
		typography: {
			fontFamily: '"BIZ UDPMincho", serif',
		},
	})
	const buttonPatternFeatures: {
		title: string
		link: string
		scroll: boolean
	}[] = [
		{
			title: 'トップ',
			link: '/',
			scroll: false,
		},
		{ title: 'お客様の声', link: 'customerReviews', scroll: false },
		{ title: 'スタッフ紹介', link: 'introduction', scroll: false },
		{ title: '予約・問い合わせ', link: 'infomation', scroll: false },
		{ title: '施術までの流れ', link: 'flow', scroll: false },
	]

	const contents: { title: string; text: string[]; src: string }[] = [
		{
			title: '1.カウンセリング＆検査',
			text: [
				'当院では、症状のある場所だけの検査は行っておりません。多くの症状は、どこかをかばって負担をかけ、痛みやしびれとなっています。根本改善する為には、お身体のどこが原因か細かく検査する必要があります。些細な事でも、話していただければ、原因追及のヒントになりますので、なんでもお話しください。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FFeatures%2F1.jpeg?alt=media&token=6dfa3b38-eb57-4f5f-b03b-388cc90b34e1',
		},
		{
			title: '2.アフターフォロー',
			text: [
				'お家でできる体操や、運動、食事のご指導を徹底しております。良い習慣を取り入れる事でお家でも体を改善していく事ができて早期改善に繋がります。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FFeatures%2F2.jpeg?alt=media&token=067c5517-80bf-44dc-893f-ba139b62da9e',
		},
		{
			title: '3.国家資格所有者の施術',
			text: [
				'痛み、しびれの施術実績が豊富なスタッフによる施術を徹底します。国家資格所持者（柔道整復師）であることは必須です。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FFeatures%2F3.jpeg?alt=media&token=ecdad6d4-b73f-478e-a5f8-77e438fb3399',
		},
		{
			title: '4.完全予約制・個室',
			text: [
				'それぞれのお客様の施術を丁寧に行う為完全予約制とさせていただいております。マンツーマンのお時間を最大限生かし、早期改善にお力添えを致します。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FFeatures%2F4.jpeg?alt=media&token=77cea0be-1fa2-46bc-a01a-4a57a06a40f3',
		},

		{
			title: '5.成果主義',
			text: [
				'未来整体は、どこに行っても良くならなかったコリ・痛み・しびれの根本改善を行います。一時しのぎでは意味がありません。お悩みを繰り返さないようにサポートいたします。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FFeatures%2F5.jpeg?alt=media&token=abd1d755-d526-4d59-8d77-17b0fdac57fd',
		},
	]
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box sx={{ backgroundColor: '#F9F1E8' }}>
				<Box sx={{ p: '20px' }}>
					<SquareLogo />
				</Box>
				<Typography align='center' sx={{ fontSize: '24px', mb: 3 }}>
					痛みしびれで悩まない人生へ
					<br />
					<br />
					あなたの、
					<Box component='span' sx={{ color: '#FB880A' }}>
						カラダ
					</Box>
					が<br />
					元気で丈夫に生まれ変わる
				</Typography>
				<CustomSubtitle text='当院の特徴' />
				{contents.map((content) => (
					<Box>
						<Box
							sx={{
								backgroundColor: '#F1C594',
								textAlign: 'center',
								paddingX: '15px',
								paddingY: '5px',
								my: 2,
							}}
						>
							<Typography
								sx={{
									color: '#000',
									fontSize: '26px',
									fontWeight: 'bold',
									fontFamily: '"BIZ UDPGothic", serif',
								}}
							>
								{content.title}
							</Typography>
						</Box>
						<Box
							component='img'
							src={content.src}
							alt={content.title}
							width='100%'
						/>
						<Box>
							{content.text.map((text) => (
								<Box key={text}>
									<Typography sx={{ color: '#000', fontSize: '20px' }}>
										{text}
									</Typography>
								</Box>
							))}
						</Box>
					</Box>
				))}
				<Typography sx={{ fontSize: '20px', mt: 5 }}>
					未来整体のホームページをご覧いただきありがとうございます。
					<br />
					<br />
					当院は、あなたが憧れる痛み・しびれで悩まない、元気で自由に動くカラダに導く
					専門院です。
				</Typography>
				<ul>
					<li>
						<Typography sx={{ my: 1, fontSize: '20px' }}>
							長年の腰痛が驚くほど良くなりました。
						</Typography>
					</li>
					<li>
						<Typography sx={{ my: 1, fontSize: '20px' }}>
							もう肩が上がらないと思っていましたが以前のように上がるようになりました。
						</Typography>
					</li>
					<li>
						<Typography sx={{ my: 1, fontSize: '20px' }}>
							お尻から足の裏までしびれていましたが今では全く気になりません。
						</Typography>
					</li>
					<li>
						<Typography sx={{ my: 1, fontSize: '20px' }}>
							数分しか歩けなかったですが、40分以上歩けるようになりました。
						</Typography>
					</li>
					<li>
						<Typography sx={{ my: 1, fontSize: '20px' }}>
							コリ・しびれが無くなったので旅行にも不安なくいく事が出来ます。
						</Typography>
					</li>
					<li>
						<Typography sx={{ my: 1, fontSize: '20px' }}>
							痛み止めを飲まなくなり、胃が荒れることがなくなりました。
						</Typography>
					</li>
					<li>
						<Typography sx={{ my: 1, fontSize: '20px' }}>
							手術は嫌だったので、改善してほんとにうれしいです。
						</Typography>
					</li>
					<li>
						<Typography sx={{ my: 1, fontSize: '20px' }}>
							朝起きた時のコリがなくなりました。
						</Typography>
					</li>
				</ul>
				<Typography sx={{ fontSize: '20px' }}>
					まず、お伝えしたいことは カラダのどこかに悩みがあっても 「
					<Box component='span' sx={{ color: '#FB880A' }}>
						自由に動けて、丈夫なカラダは手に入れられる
					</Box>
					」という事です。
					<br />
					<br /> あなたの関節・筋肉の連動性を 取り戻して行くにつれて、お悩みが
					改善していきます。
					<br />
					<br /> コリ・痛み・しびれが 当院の整体＋ストレッチで悩むことは
					なくなります。
					<br />
					<br />
					憧れる元気なカラダを手に入れましょう。
					<br />
					<br />
					未来整体の整体は、従来のマッサージのような施術や、ボキボキした施術とは違うのが特徴です。
					<br />
					<br />
					悩みのある場所だけでなく、悩みの 根本原因を特定し、姿勢や関節の動きを
					優しい整体と姿勢矯正をしていきます。
					<br />
					<br /> そして、身体が整うにつれて 以前のように元気になっていきます。
				</Typography>
				<ButtonGroup pattern={buttonPatternFeatures} />
			</Box>
		</ThemeProvider>
	)
}
