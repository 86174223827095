import React from 'react'
import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme } from '@mui/material/styles'
import CampaignImage from './CampaignImage'
export default function Contact() {
	const theme = createTheme({
		palette: {
			primary: {
				main: '#1AEC19',
			},
		},
		typography: {
			fontFamily: '"BIZ UDPMincho", serif',
		},
	})
	return (
		<>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Box sx={{ textAlign: 'center', backgroundColor: '#FCECDA' }}>
					<Box sx={{ backgroundColor: '#F8C994', mb: 3, mx: 3 }}>
						<Typography sx={{ fontSize: '16px' }}>
							ご予約お問い合わせはこちら
						</Typography>
					</Box>
					<Box sx={{ my: 3 }}>
						<Button variant='contained' color='primary'>
							<Link
								underline='none'
								href='https://page.line.me/522hcmgv?oat_content=url&openQrModal=true'
							>
								<Typography sx={{ fontSize: '36px', color: '#FFF' }}>
									24時間LINE予約
								</Typography>
							</Link>
						</Button>
					</Box>
					<Typography sx={{ fontSize: '24px' }}>
						電話でのご予約・お問い合わせは
					</Typography>
					<Link href='tel:088-660-4631' color='inherit'>
						<Typography sx={{ color: '#000', fontSize: '36px' }}>
							088-660-4631
						</Typography>
					</Link>
					<Typography sx={{ fontSize: '30px' }}>
						受付時間
						<br />
						月〜日（土日も可）
						<br />
						10:00〜21:00
					</Typography>
					<Box sx={{ mt: 3 }}>
						<CampaignImage />
					</Box>
				</Box>
			</ThemeProvider>
		</>
	)
}
