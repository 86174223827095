import React from 'react'

import SquareLogo from './SquareLogo'
import { Box, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { Map } from './Map'
import { Marker } from './Marker'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme } from '@mui/material/styles'
import Contact from './Contact'
import ButtonGroup from './ButtonGroup'
const render = (status: Status) => {
	return <h1>{status}</h1>
}

export default function Info() {
	const position: google.maps.LatLngLiteral = {
		lat: 34.044773,
		lng: 134.5787762,
	}
	const theme = createTheme({
		typography: {
			fontFamily: '"BIZ UDPMincho", serif',
			fontSize: 16,
		},
	})

	const buttonPatternInfo: {
		title: string
		link: string
		scroll: boolean
	}[] = [
		{
			title: 'トップ',
			link: '/',
			scroll: false,
		},
		{ title: '当院の特徴', link: 'features', scroll: false },
		{ title: 'お客様の声', link: 'customerReviews', scroll: false },
		{ title: 'スタッフ紹介', link: 'introduction', scroll: false },
		{ title: '施術までの流れ', link: 'flow', scroll: false },
	]

	return (
		<>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Box sx={{ padding: '20px', backgroundColor: '#F9F1E8' }}>
					<SquareLogo />
				</Box>
				<Contact />
				<Box sx={{ backgroundColor: '#FCECDA' }}>
					<Box sx={{ p: 3 }}>
						<Typography sx={{ fontSize: '36px' }}>院情報</Typography>
						<Typography sx={{ fontSize: '16px' }}>
							受付時間(完全予約制)
							<br />
							月〜日（土日も診療中）
							<br />
							10:00〜21:00
						</Typography>
						<Link
							color='inherit'
							href='https://page.line.me/522hcmgv?oat_content=url&openQrModal=true'
						>
							<Typography sx={{ color: '#000', my: 3, fontSize: '16px' }}>
								24時間
								<Box
									component='span'
									sx={{ color: '#1AEC19', fontSize: '24px' }}
								>
									LINE
								</Box>
								予約はこちらをタップ
							</Typography>
						</Link>
						<Typography>電話予約はこちら</Typography>
						<Link href='tel:088-660-4631' color='inherit'>
							<Typography sx={{ color: '#000', fontSize: '24px' }}>
								088-660-4631
							</Typography>
						</Link>

						<Typography sx={{ my: 2, fontSize: '16px' }}>
							施術
							<br />
							痛み・しびれ専門の整体
						</Typography>
						<Typography sx={{ my: 2, fontSize: '16px' }}>
							駐車場
							<br />
							2台
						</Typography>
						<Typography sx={{ fontSize: '18px' }}>
							料金（ニ回目以降は施術料のみ）
						</Typography>
						<Typography
							sx={{
								fontSize: '20px',
								fontWeight: 'bold',
								fontFamily: '"BIZ UDPGothic", serif',
							}}
						>
							施術料：5,500円(税込)
							<br />
							検査料：2,200円(税込)
						</Typography>
						<Typography
							sx={{ color: '#FF0000', fontSize: '20px', fontWeight: 'bold' }}
						>
							初回限定キャンペーン中
							<br />
							検査料＋施術料あわせて
							<br />
							5,500円（税込）
						</Typography>
						<Typography>※初診時は90分前後お時間をいただきます。</Typography>
						<Typography sx={{ my: 2, fontSize: '16px' }}>
							支払い方法
							<br />
							現金・キャッシュレス各種対応
						</Typography>
						<Typography sx={{ my: 2, fontSize: '16px' }}>
							住所
							<br />
							〒770-8003
							<br />
							徳島市津田本町5-2-38
						</Typography>
						<Box
							component='img'
							src='https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FInfo%2Fgaikan1.jpeg?alt=media&token=a59fd423-af21-449d-901c-0b55c83c3396'
							alt='未来整体の外観'
							width='100%'
						/>
					</Box>
					<Box sx={{ mt: 1, mb: 3 }}>
						<Wrapper
							apiKey={process.env.REACT_APP_MAPS_JAVASCRIPT_APIKEY}
							render={render}
						>
							<Map
								style={{ width: '95%', aspectRatio: '346/134' }}
								center={position}
								zoom={15}
							>
								<Marker position={position} />
							</Map>
						</Wrapper>
					</Box>
					<ButtonGroup pattern={buttonPatternInfo} />
				</Box>
			</ThemeProvider>
		</>
	)
}
