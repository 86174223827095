import React from 'react'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import SquareLogo from './SquareLogo'
import TopImage from './TopImage'
import { Box } from '@mui/material'
import ButtonGroup from './ButtonGroup'

const buttonPatternTop: { title: string; link: string; scroll: boolean }[] = [
	{
		title: 'お客様の声',
		link: 'customerReviews',
		scroll: false,
	},
	{ title: '当院の特徴', link: 'features', scroll: false },
	{ title: 'スタッフ紹介', link: 'introduction', scroll: false },
	{ title: '予約・問い合わせ', link: 'infomation', scroll: false },
	{ title: '施術までの流れ', link: 'flow', scroll: false },
]

export default function TopPage() {
	const theme = createTheme({
		palette: {
			primary: {
				main: '#D97D1C',
			},
		},
	})

	return (
		<>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Box sx={{ backgroundColor: '#F9F1E8' }}>
					<Box id='top' sx={{ padding: '20px' }}>
						<SquareLogo />
					</Box>
					<TopImage />
					<ButtonGroup pattern={buttonPatternTop} />
				</Box>
			</ThemeProvider>
		</>
	)
}
