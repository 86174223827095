import React from 'react'
import { Box } from '@mui/material'

export default function CampaignImage() {
	return (
		<>
			<Box sx={{ textAlign: 'center' }}>
				<Box
					component='img'
					src='https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FContact%2FCampaign.png?alt=media&token=5c8dcb5f-fd27-4349-875f-77ea8f7678f7'
					alt='期間限定キャンペーン実施中'
					width='100%'
				/>
			</Box>
		</>
	)
}
