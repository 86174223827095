import React from 'react'
import './App.css'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import TopPage from './Components/TopPage'
import Introduction from './Components/introductions'
import Info from './Components/Info'
import Flow from './Components/Flow'
import CustomerReviews from './Components/CustomerReviews'
import Features from './Components/Features'

function App() {
	return (
		<Router>
			<Routes>
				<Route path='/' element={<TopPage />} />
				<Route path='introduction' element={<Introduction />} />
				<Route path='infomation' element={<Info />} />
				<Route path='flow' element={<Flow />} />
				<Route path='customerReviews' element={<CustomerReviews />} />
				<Route path='features' element={<Features />} />
			</Routes>
		</Router>
	)
}

export default App
