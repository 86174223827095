import React from 'react'
import { Typography, Box } from '@mui/material'

import SquareLogo from './SquareLogo'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme } from '@mui/material/styles'
import Grid from '@mui/material/Unstable_Grid2'
import ButtonGroup from './ButtonGroup'

export default function Introduction() {
	const theme = createTheme({
		typography: {
			fontFamily: '"BIZ UDPMincho", serif',
		},
	})

	const buttonPatternIntroduction: {
		title: string
		link: string
		scroll: boolean
	}[] = [
		{
			title: 'トップ',
			link: '/',
			scroll: false,
		},
		{ title: '当院の特徴', link: 'features', scroll: false },
		{ title: 'お客様の声', link: 'customerReviews', scroll: false },
		{ title: '予約・問い合わせ', link: 'infomation', scroll: false },
		{ title: '施術までの流れ', link: 'flow', scroll: false },
	]

	return (
		<>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Box sx={{ backgroundColor: '#F9F1E8' }}>
					<Box sx={{ p: '20px' }}>
						<SquareLogo />
					</Box>
					<Typography sx={{ fontSize: '48px' }}>院長紹介</Typography>
					<Grid container spacing={0}>
						<Grid xs={7} display='flex' justifyContent='left'>
							<Box
								component='img'
								src='https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FIntroduction%2Fintroduction.jpeg?alt=media&token=2a601b14-6c6a-4082-a4d5-2a633a3960d4'
								alt='未来整体院長/屋敷平信輔'
								width='100%'
							/>
						</Grid>
						<Grid xs={5} display='flex' justifyContent='left'>
							<Box>
								<Typography sx={{ fontSize: '14px' }}>ﾔｼｷﾋﾗｼﾝｽｹ</Typography>
								<Typography sx={{ fontSize: '22px' }}>屋敷平信輔</Typography>
								<Typography sx={{ fontSize: '24px' }}>
									国家資格
									<br />
									柔道整復師
									<br />
									<br />
									臨床経験
									<br />
									1万人以上
								</Typography>
							</Box>
						</Grid>
					</Grid>
					<Box sx={{ mx: 2 }}>
						<Typography
							sx={{ fontSize: '24px', my: 2, textDecorationLine: 'underline' }}
						>
							治療家になったきっかけ
						</Typography>
						<Typography sx={{ fontSize: '24px', my: 2 }}>
							８歳から２０歳まで硬式テニスに没頭していました
						</Typography>
						<Typography sx={{ fontSize: '24px', my: 2 }}>
							練習中に怪我が多く、
							中学生の頃から電気治療とマッサージを受けに「整骨院」に
							週２回通っていました。
						</Typography>
						<Typography sx={{ fontSize: '24px', my: 2 }}>
							しかし、いつまでたっても治らず
							膝と腰が痛くて練習できない日が続きました。別の病院では、
							「腰椎すべり症」という診断を
							受け、痛み止めとシップの治療を行いましたが症状は変わらず...
							悩んでいた私は 自費での治療を行っている
							「整体院」に行くようにしました。
						</Typography>
						<Typography sx={{ fontSize: '24px', my: 2 }}>
							１回治療費は一般的な保険適応の「整骨院」と比べると高価でしたが体はみるみる改善していきました。
						</Typography>
						<Typography sx={{ fontSize: '24px', my: 2 }}>
							おかげさまで テニスの練習に本腰を入れることができ、大阪ベスト 16
							まで 上り詰めることができました。
						</Typography>
						<Typography sx={{ fontSize: '24px', my: 2 }}>
							練習を諦めかけていた私を救ってくれた「整体院」の先生に感謝しています。
						</Typography>
						<Typography sx={{ fontSize: '24px', my: 2 }}>
							それをきっかけに「整体院」開業 という夢をもちました。
							国家資格を取得し、
							のべ1万人以上の臨床経験。スタッフ・院長を経験し、
							開業する運びとなりました。
						</Typography>
						<Typography sx={{ fontSize: '24px', my: 2 }}>
							私のように どこに行っても改善しない方や 一人で悩んでいる方が
							未来に向かい、前を向いて元気に生きていけるよう
							未来整体は、邁進していきます。
						</Typography>
					</Box>

					<ButtonGroup pattern={buttonPatternIntroduction} />
				</Box>
			</ThemeProvider>
		</>
	)
}
