import React from 'react'
import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import { Link as Scroll } from 'react-scroll'
import Link from '@mui/material/Link'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme } from '@mui/material/styles'

interface MyTopicsProps {
	pattern: { title: string; link: string; scroll: boolean }[]
}

export default function ButtonGroup({ pattern }: MyTopicsProps) {
	const theme = createTheme({
		palette: {
			primary: {
				main: '#E47F10',
			},
		},
		typography: {
			fontFamily: '"BIZ UDPMincho", serif',
		},
	})
	return (
		<>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Box sx={{ textAlign: 'center', p: 2 }}>
					<Grid container spacing={2}>
						{pattern.map((contents) => (
							<Grid xs={6}>
								{contents.scroll ? (
									<Scroll
										to={contents.link}
										smooth={true}
										duration={600}
										offset={-20}
									>
										<Button variant='contained' color='primary' fullWidth>
											<Typography
												sx={{
													color: '#FFF',
													fontSize: '16px',
												}}
											>
												{contents.title}
											</Typography>
										</Button>
									</Scroll>
								) : (
									<Link href={contents.link}>
										<Button variant='contained' color='primary' fullWidth>
											<Typography
												sx={{
													color: '#FFF',
													fontSize: '16px',
												}}
											>
												{contents.title}
											</Typography>
										</Button>
									</Link>
								)}
							</Grid>
						))}
					</Grid>
				</Box>
			</ThemeProvider>
		</>
	)
}
