import React from 'react'
import CustomSubtitle from './CustomSubtitle'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme } from '@mui/material/styles'
import ButtonGroup from './ButtonGroup'
import SquareLogo from './SquareLogo'
import Recommendation from './Recommendation'

export default function CustomerReviews() {
	const theme = createTheme({
		typography: {
			fontFamily: '"BIZ UDPMincho", serif',
		},
	})

	const buttonPatternCustomerReviews: {
		title: string
		link: string
		scroll: boolean
	}[] = [
		{
			title: 'トップ',
			link: '/',
			scroll: false,
		},
		{ title: '当院の特徴', link: 'features', scroll: false },
		{ title: '施術までの流れ', link: 'flow', scroll: false },
		{ title: '予約・問い合わせ', link: 'infomation', scroll: false },
		{ title: 'スタッフ紹介', link: 'introduction', scroll: false },
	]

	const Q: string[] = [
		'①どのような症状で悩まれていましたか？',
		'②悩みを解決する前にどんな治療をされてきましたか？',
		'③当院の治療を受けられて、変化はありましたか？',
		'④同じ症状で悩んでいる方に一言',
	]
	const contents: {
		A: string[]
		person: string
		comment: string[]
		src: string
		isImg: boolean
	}[] = [
		{
			A: [
				'1か月前から首から肩の痛み。',
				'はじめは寝違えかな？と思っていましたがシップと痛み止めも飲んでも効果が全くなく、悩んでいました。',
				'痛みは完全になくなり、車の運転時に以前よりも後ろに振り向きやすくなりました。',
				'信頼できる技術をお持ちですので、一度相談してみてください。',
			],
			person: '(徳島市 50代 男性)',
			comment: [
				'お悩みの原因が、背骨の動きが悪い事だと分かり、関節の動きの矯正を徹底して行いました。動きやすくなるにつれ、改善していきました。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FCustomerReviews%2Fcustomer1.jpeg?alt=media&token=96d13b2c-dbe4-40e9-b4bd-b1186d59d817',
			isImg: true,
		},
		{
			A: [
				'数ヵ月続く腰痛と両足のしびれ',
				'病院で注射を4回打ちましたが全く改善しなかった。',
				'歩く時のしびれが全くなくなり、歩くスピードが倍になりました。腰痛も改善し、嬉しいです。',
				'痛みやしびれで悩まれている方は、まず相談されてみてはいかがでしょうか。経験も豊富ですし、適切なアドバイスいただけます。',
			],
			person: '(小松島市 80代 女性)',
			comment: [
				'注射をしても良くならない原因が、神経を痛めており、一定の負担をかけた時に、再負傷してしまう事にありました。固まっている関節が正常に動くように施術していき、患部に負担がかからないように姿勢改善をしました。時間はかかりましたが、少しずつ改善されました。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FCustomerReviews%2Fcustomer2.jpeg?alt=media&token=8e3f1ee7-1b1e-42be-b5ec-30d086839d5d',
			isImg: true,
		},
		{
			A: [
				'1年前から膝痛（変形性膝関節症）',
				'シップを貼ったり、痛み止めを飲んでました。',
				'施術を受けるうちに、動く範囲が広がっていき、痛みも取れました。今は以前のように自由に動きます。',
				'家で出来る体操も教えてもらえますので、実践していけばより早く良くなると思います。',
			],
			person: '(徳島市 70代 男性)',
			comment: [
				'膝の状態よりも、股関節の動きが悪く、股関節をかばって膝に負担がかかっていました。股関節と膝の連動性が良くなるにつれて改善されました。体操などもお伝えし、以前と同じように動くまで回復されました。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FCustomerReviews%2Fcustomer3.jpeg?alt=media&token=3b43186c-6c67-43a0-9072-db235b953884',
			isImg: true,
		},
		{
			A: [
				'1年前からの肩痛で肩が上がらなかったです。',
				'YouTubeで観たストレッチでケアしていましたが、一向に改善しませんでした。',
				'施術を受けるうちに、動く範囲が広がっていき、痛みも取れました。今は以前のように自由に動きます。',
				'家で出来る体操も教えてもらえますので、実践していけばより早く良くなると思います。',
			],
			person: '(小松島市 30代 男性)',
			comment: [
				'肩痛の状態よりも、猫背がひどく、姿勢矯正を徹底。猫背が改善するに連れて、肩が上がるように。肩と背骨は連動して動くことをご説明し、日頃出来る体操もお教えいたしました。以前と同じように動くまで回復されました。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FCustomerReviews%2Fcustomer4.jpeg?alt=media&token=6d942e7f-9599-4f27-8b3a-6577a7be2875',
			isImg: true,
		},
		{
			A: [
				'数年前から肩こりや腰コリに悩んでおり、猫背など姿勢の悪さも気になっていました。',
				'ストレッチや少し動かすぐらいで、何をしていいのか分かりませんでした。',
				'数回施術を受けるうちに、肩こりや腰コリが減り、今ではほとんどなくなりました。',
				'年のせいにしてはいけません。悩んでいたら、一度未来整体へ相談してみてください！',
			],
			person: '(小松島市 30代 男性)',
			comment: [
				'今まで積み重ねて来られた歪みが原因で、筋肉・骨が固まりきっていました。通院に加えて家でできる体操を習慣化ししてもらいなが数か月かけてコツコツ改善していかれました。猫背や歪みは見違える程改善されました。',
			],
			src: 'https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FCustomerReviews%2Fcustomer5.jpeg?alt=media&token=d5ffde21-7260-4ddb-9d65-94bc4d3f1d5d',
			isImg: true,
		},
	]
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Box sx={{ backgroundColor: '#F9F1E8' }}>
				<Box sx={{ p: '20px' }}>
					<SquareLogo />
				</Box>
				<CustomSubtitle text='患者様の声' />
				{contents.map((content) => (
					<Box>
						{content.isImg ? (
							<Box sx={{ textAlign: 'center' }}>
								<Box
									component='img'
									src={content.src}
									alt='患者様'
									width='80%'
									sx={{ borderRadius: '50%' }}
								/>
							</Box>
						) : (
							<></>
						)}

						<Box sx={{ border: 'solid 3px #000', p: 1, m: 1 }}>
							{Q.map((q, i) => (
								<>
									<Typography
										sx={{
											fontSize: '14px',
											fontWeight: 'bold',
											fontFamily: '"BIZ UDPGothic", sans-serif',
										}}
									>
										{q}
									</Typography>
									<Typography
										sx={{
											fontSize: '16px',
											fontFamily: '"BIZ UDPMincho", sans-serif',
											mb: 2,
										}}
									>
										{content.A[i]}
									</Typography>
								</>
							))}
							<Grid container spacing={0}>
								<Grid xs={6}>
									<Typography>{content.person}</Typography>
								</Grid>
								<Grid xs={6}>
									<Typography align='right'>※個人の感想です</Typography>
								</Grid>
							</Grid>

							<Grid container spacing={0}>
								<Grid
									xs={4}
									display='flex'
									justifyContent='right'
									alignItems='center'
								>
									<Box
										component='img'
										src='https://firebasestorage.googleapis.com/v0/b/arrange-2-4.appspot.com/o/v202403%2FCustomerReviews%2Fcomment.png?alt=media&token=ef6ff53b-32f1-4448-a0ae-8e586dbc2c72'
										alt='担当者'
										height='60px'
									/>
								</Grid>
								<Grid
									xs={8}
									display='flex'
									justifyContent='left'
									alignItems='center'
								>
									<Typography
										sx={{
											fontSize: '18px',
											fontWeight: 'bold',
											textDecorationLine: 'underline',
											textDecorationColor: '#E48010',
											textDecorationThickness: '3px',
											ml: 2,
										}}
									>
										担当者からのコメント
									</Typography>
								</Grid>
								<Grid xs={12}>
									<Typography
										sx={{
											fontSize: '16px',
											fontFamily: '"BIZ UDPMincho", sans-serif',
										}}
									>
										{content.comment}
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Box>
				))}
				<Recommendation />
				<ButtonGroup pattern={buttonPatternCustomerReviews} />
			</Box>
		</ThemeProvider>
	)
}
